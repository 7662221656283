import React from 'react'
import {graphql} from 'gatsby'
import Layout from "../components/layout"

export default ({ data }) => {
    const page = data.storyblokEntry
    const pageContent = JSON.parse(page.content)
    const pageImages = pageContent.images
    return (
      <Layout>
            <section style={{
            margin: `0 auto`,
            maxWidth: 1066
            }}>
                <h1>{pageContent.title}</h1>
                <p>{pageContent.body_copy}</p>

{pageImages.map((image) => (
          <div>
              <img src={image.filename} alt=""/>
          </div>
        ))}
                
            </section>
      </Layout>
    )
  }

export const query = graphql`
  query($slug: String!) {
    storyblokEntry(slug: { eq: $slug }) {
      content
    }
  }
`